<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='qfa m-auto'>
        <div class='flex mt-8 text-gray-700'>
          <router-link class='flex'
            :to='{ name: "settings" }'>
            <i class='icon'>arrow_back</i>
          </router-link>
          <div class='ml-16 text-lg font-bold'>{{ $t('pages.faqs.8b5a8172') }}</div>
        </div>
        <skeleton-loader-component class='mt-24'
          :loading='!faqs'>
          <div class='das'
            v-for='(topic, index) in faqs'
            :key='index'>
            <header-element>{{ topic.title }}</header-element>
            <card-element class='py-8 mt-24'>
              <collapse-element class='fco'
                v-for='(faq, index) in topic.faqs'
                :key='index'>
                <template #header>
                  <div class='flex justify-between py-16 cursor-pointer'>
                    <div>{{ faq.question }}</div>
                    <i class='icon text-gray-700'>chevron_right</i>
                  </div>
                </template>
                <div class='flex items-start pb-16'>
                  <i class='icon text-gray-500'>arrow_right</i>
                  <div class='html-content ml-8 text-gray-700'
                    v-html='faq.answer'>
                  </div>
                </div>
              </collapse-element>
            </card-element>
          </div>
        </skeleton-loader-component>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import SkeletonLoader from '@/components/skeleton-loader'
  import TopNavigation from '@/components/top-navigation'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'skeleton-loader-component': SkeletonLoader,
      'top-navigation-component': TopNavigation
    },

    computed: {
      faqs () {
        return this.$store.state.settings.faqs
      }
    }
  }
</script>

<style lang='scss' scoped>
  .qfa {
    max-width: 640px;
  }

  .das {
    &:not(:first-child) {
      @apply mt-24;
    }
  }

  .fco {
    &:not(:first-child) {
      @apply
        border-gray-300
        border-t;
    }
  }
</style>
